import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ description, meta = {}, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            url
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        { property: `og:url`, content: site.siteMetadata.url },
        {
          property: `og:image`,
          content: `${
            site.siteMetadata.url
          }${require('../images/background.jpg')}`,
        },
        {
          property: `og:image:width`,
          content: `2048`,
        },
        {
          property: `og:image:height`,
          content: `1365`,
        },
      ].concat(meta)}
    >
      <script script-src="self" src="https://apis.google.com/js/platform.js" />
    </Helmet>
  )
}

export default SEO
